import InputErrorMessage from 'components/InputErrorMessage';
import AlertIcon from 'images/alert-triangle.svg';
import { Mask } from 'model/mask-types';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StringUtils from 'shared/util/string-utils';
import { ErrorMessageBox, InputContainer, Label, Required, Spin, TextArea, Title } from './styles';

interface InputTextAreaProps {
  name: string;
  label?: string;
  inputType?: string;
  style?: any;
  disabled?: boolean;
  readOnly?: boolean;
  isWhite?: boolean;
  showErrorIcon?: boolean;
  onChange?: (e: any) => void;
  mask?: string;
  defaultValue?: string | JSX.Element;
  labelClassName?: string;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  errorMessage?: string;
  isRequired?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  rightIcon?: JSX.Element;
  activated?: boolean;
  width?: number;
  isLoading?: boolean;
  flexWidth?: boolean;
  notIcon?: boolean;
  isElement?: boolean;
}

const InputTextArea: React.FC<InputTextAreaProps> = ({
  name,
  label,
  inputType,
  style,
  disabled = false,
  isWhite = false,
  onChange,
  mask,
  defaultValue,
  readOnly,
  labelClassName,
  maxLength,
  minLength,
  showErrorIcon,
  placeholder,
  errorMessage,
  isRequired,
  onBlur,
  onFocus,
  rightIcon,
  activated,
  width,
  isLoading = false,
  flexWidth,
  notIcon,
  isElement,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const {
    register,
    getValues,
    formState: { errors, dirtyFields },
    setValue,
  } = useFormContext();

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    setIsFocused(false);
  };

  const handleGetValue = () => {
    const values = getValues();
    return Boolean(values[name]);
  };

  const hasDefaultValue = () => {
    if ((defaultValue || handleGetValue()) && !dirtyFields[name]) {
      return true;
    }
    return dirtyFields[name];
  };
  const styledComponentsProps = {
    isTouched: isFocused,
    hasError: !!errors[name] || errorMessage != null,
    hasValue: !disabled ? handleGetValue() || hasDefaultValue() : false,
    isFocused,
    isValid: !disabled ? !errors[name] && !isFocused && hasDefaultValue() : false,
  };

  const handleRegister = register(name);

  const handleSetMask = (value: string | number) => {
    const text = value.toString();
    const MaskFormats = {
      [Mask.CURRENCY]: StringUtils.currencyMask(text),
      [Mask.PHONE]: StringUtils.phoneMask(text),
      [Mask.CNPJ]: StringUtils.cnpjMask(text),
      [Mask.CPF]: StringUtils.cpfMask(text),
      [Mask.CEP]: StringUtils.zipCodeMask(text),
      [Mask.LANDLINE]: StringUtils.landlineMask(text),
      [Mask.PERCENTAGE]: StringUtils.percentagePtMask(text),
      [Mask.NUMBERS]: StringUtils.removeNonNumbersFromMaskedValue(text),
      [Mask.RG]: StringUtils.rgMask(text),
      [Mask.BIRTHDAY]: StringUtils.birthdayMask(text),
      [Mask.MONTH_YEAR]: StringUtils.monthYearMask(text),
      [Mask.FULLNAME]: StringUtils.fullNameMask(text),
      [Mask.MONEY]: StringUtils.moneyMaskPtbr(Number(text)),
      [Mask.ANNUAL_PERCENTAGE]: StringUtils.annualPercentageMask(text),
      [Mask.DEFAULT]: text,
    };

    setValue(name, MaskFormats[mask ?? Mask.DEFAULT]);
  };

  const handleChange = (e: any) => {
    handleSetMask(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  useEffect(() => {
    if (defaultValue && typeof defaultValue === 'string') {
      handleSetMask(defaultValue);
    }
    return;
  }, [defaultValue]);

  return (
    <Label htmlFor={`#input-${name}`} {...styledComponentsProps} className={labelClassName} style={style} activated={activated}>
      {label && (
        <>
          <Title isFocused={isFocused} activated={activated} error={styledComponentsProps.hasError}>
            {label}
          </Title>
          {isRequired && <Required>*</Required>}
        </>
      )}
      <InputContainer
        {...styledComponentsProps}
        width={width}
        error={styledComponentsProps.hasError}
        activated={activated}
        flexWidth={flexWidth}
      >
        {!isElement && (
          <TextArea
            id={`#input-${name}`}
            onFocus={handleFocus}
            {...register(name)}
            onBlur={handleBlur}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxLength}
            minLength={minLength}
            placeholder={isFocused ? '' : placeholder}
            {...props}
            onChange={e => {
              handleChange(e);
              handleRegister.onChange(e);
            }}
            error={styledComponentsProps.hasError}
            autoComplete="nope"
          />
        )}
        {isLoading && <Spin />}
        {rightIcon && <section>{rightIcon}</section>}
        {isElement && defaultValue}
      </InputContainer>
      {(styledComponentsProps.hasError || errorMessage) && (
        <ErrorMessageBox>
          <img src={AlertIcon} style={{ marginRight: '5px', maxWidth: '35px' }} />
          <InputErrorMessage isFocused={isFocused} errorMessage={errors[name]?.message ?? errorMessage} />
        </ErrorMessageBox>
      )}
    </Label>
  );
};

export default InputTextArea;
